/*
	Simple structure for holding grid coordinates and extra data about them.

	@author Corey Birnbaum https://github.com/vonWolfehaus/

	- CVER : 
		- Ajout de setAdjacentCells
		- Ajout de adjacentCells
*/
vg.Cell = function(q, r, s, h) {
	this.q = q || 0; // x grid coordinate (using different letters so that it won't be confused with pixel/world coordinates)
	this.r = r || 0; // y grid coordinate
	this.s = s || 0; // z grid coordinate
	this.h = h || 1; // 3D height of the cell, used by visual representation and pathfinder, cannot be less than 1
	this.tile = null; // optional link to the visual representation's class instance
	this.adjacentCells = []; // Contient les id des cellules adjacentes
	this.userData = {}; // populate with any extra data needed in your game
	this.walkable = true; // if true, pathfinder will use as a through node
	// rest of these are used by the pathfinder and overwritten at runtime, so don't touch
	this._calcCost = 0;
	this._priority = 0;
	this._visited = false;
	this._parent = null;
	this.uniqueID = vg.LinkedList.generateID();
};

vg.Cell.prototype = {
	set: function(q, r, s) {
		this.q = q;
		this.r = r;
		this.s = s;
		return this;
	},

	copy: function(cell) {
		this.q = cell.q;
		this.r = cell.r;
		this.s = cell.s;
		this.h = cell.h;
		this.tile = cell.tile || null;
		this.adjacentCells = cell.adjacentCells || [];
		this.userData = cell.userData || {};
		this.walkable = cell.walkable;
		return this;
	},

	add: function(cell) {
		this.q += cell.q;
		this.r += cell.r;
		this.s += cell.s;
		return this;
	},

	equals: function(cell) {
		return this.q === cell.q && this.r === cell.r && this.s === cell.s;
	},

	// 2 cellules sont adjentes si entre les coordonées Q, R et S, une de ces coordonées est éguale pour les 2 cellules
	// et 2 ont une différence absolue de 1
	setAdjacentCells: function(cells) {
		var adjacentCells = [];
		for (var i = 0; i < cells.length; i++) {
			if ( 	(this.q === cells[i].q && Math.abs(this.r - cells[i].r) === 1 &&  Math.abs(this.s - cells[i].s) === 1) || 
					(this.r === cells[i].r && Math.abs(this.s - cells[i].s) === 1 &&  Math.abs(this.q - cells[i].q) === 1) ||
					(this.s === cells[i].s && Math.abs(this.q - cells[i].q) === 1 &&  Math.abs(this.q - cells[i].q) === 1)
			) {
				this.adjacentCells.push(cells[i]);
			}
		}
	}
};

vg.Cell.prototype.constructor = vg.Cell;
