'use strict';

/* ------- CONFIG -------- */
//var generalConfig = "LOCAL";
//var generalConfig = "DEV";
var generalConfig = "PROD";

var loginLink = '/#/logout';
var baseURL = 'non_initialisé';
var mobileWidth = 800;
var showParticules = false;
var oauthClientId = "non_initialisé";
var oauthClientSecret = "non_initialisé";

// local
if (generalConfig == "LOCAL"){
    oauthClientId = "onehr";
    oauthClientSecret = "secret";
    baseURL = 'http://digiclashdev.myje.fr/digiclash';
}

// dev
if (generalConfig == "DEV"){
    oauthClientId = "geRZaUnySG";
    oauthClientSecret = "RQAemVAxx";
    baseURL = 'http://digiclashdev.myje.fr/digiclash';
}

// prod
if (generalConfig == "PROD"){
    oauthClientId = "RZyuILapppySG";
    oauthClientSecret = "DPMlekqieo";
    baseURL = 'https://digiclash.myje.fr/digiclash';
}

/* ----- FIN CONFIG ------ */ 


var digiclashApp = angular.module('digiclash', 
	[
        'ui.router',
        'ui.bootstrap',
        'angularOauth',
        'ngCookies',
        'ngSanitize'
    ]
);

// 
digiclashApp.run(function($rootScope) 
{    
    $rootScope.loginLink = loginLink;

    var setIsMobile = function(){
        $rootScope.isMobile = window.innerWidth < mobileWidth;
        $rootScope.$apply();
    }

    window.onresize = function() {
        setIsMobile();
    };
    setIsMobile();
})

.config(function($stateProvider, $urlRouterProvider, oauthConfigProvider, $httpProvider) {

    // Default redirection
	$urlRouterProvider.otherwise('/home');

    $stateProvider
        
        // Page /home
        .state('home', {
            url: '/home',
            templateUrl: 'src/partials/hexa-view.html',
            controller: 'HexaViewCtrl',
            controllerAs: 'vm',
            data : { pageTitle: 'Digiclash' },
            resolve: {
                userInfos: function (globalServices) {
                    // Return   soit les infos de l'utilisateur connecté
                    //          soit une 401 unauthorized (bber ne pouvait pas renvoyer de 200 avec null comme réponse)
                    return globalServices.getLoggedUser({}).then(function(response){
                        return response;
                    });
                },
                endGameDate: function(globalServices){
                    // Return   soit les infos de l'utilisateur connecté
                    //          soit une 401 unauthorized (bber ne pouvait pas renvoyer de 200 avec null comme réponse)
                    return globalServices.startGameDate({}).then(function(response){
                        if (response.status < 400) {
                            return response.data.dateFin;
                        } else {
                            return null;
                        }
                    });
                }
            }
        })

        // Page /home/action. Même que /home mais sans l'animation
        .state('home/action', {
            url: '/home/action',
            templateUrl: 'src/partials/hexa-view.html',
            controller: 'HexaViewCtrl',
            controllerAs: 'vm',
            data : { pageTitle: 'Digiclash' },
            resolve: {
                userInfos: function (globalServices) {
                    // Return   soit les infos de l'utilisateur connecté
                    //          soit une 401 unauthorized (bber ne pouvait pas renvoyer de 200 avec null comme réponse)
                    return globalServices.getLoggedUser({}).then(function(response){
                        return response;
                    });
                },
                endGameDate: function(globalServices){
                    // Return   soit les infos de l'utilisateur connecté
                    //          soit une 401 unauthorized (bber ne pouvait pas renvoyer de 200 avec null comme réponse)
                    return globalServices.startGameDate({}).then(function(response){
                        if (response.status < 400) {
                            return response.data.dateFin;
                        } else {
                            return null;
                        }
                    });
                }
            }
        })

        // Page /statistiques
        .state('stats', {
        	url: '/statistiques',
            templateUrl: 'src/partials/statistiques.html',
            controller: 'StatsCtrl',
            controllerAs: 'vm',
            data : { pageTitle: 'Digi-statistiques' },
            resolve: {
                userInfos: function (globalServices) {
                    // Return   soit les infos de l'utilisateur connecté
                    //          soit une 401 unauthorized (bber ne pouvait pas renvoyer de 200 avec null comme réponse)
                    return globalServices.getLoggedUser({}).then(function(response){
                        return response;
                    });
                },
                endGameDate: function(globalServices){
                    // Return   soit les infos de l'utilisateur connecté
                    //          soit une 401 unauthorized (bber ne pouvait pas renvoyer de 200 avec null comme réponse)
                    return globalServices.startGameDate({}).then(function(response){
                        if (response.status < 400) {
                            return response.data.dateFin;
                        } else {
                            return null;
                        }
                    });
                }
            } 
        })

        // Page /equipe
        .state('equipe', {
            url: '/equipe',
            templateUrl: 'src/partials/team.html',
            controller: 'TeamCtrl',
            controllerAs: 'vm',
            data : { pageTitle: 'Digi-team' },
            resolve: {
                userInfos: function (globalServices) {
                    // Return   soit les infos de l'utilisateur connecté
                    //          soit une 401 unauthorized (bber ne pouvait pas renvoyer de 200 avec null comme réponse)
                    return globalServices.getLoggedUser({}).then(function(response){
                        return response;
                    });
                }
            } 
        })

        // Page /territoires
        .state('territoire', {
            url: '/territoire',
            templateUrl: 'src/partials/territory.html',
            controller: 'TerritoryCtrl',
            controllerAs: 'vm',
            data : { pageTitle: 'Digi-territoires' },
            resolve: {
                userInfos: function (globalServices) {
                    // Return   soit les infos de l'utilisateur connecté
                    //          soit une 401 unauthorized (bber ne pouvait pas renvoyer de 200 avec null comme réponse)
                    return globalServices.getLoggedUser({}).then(function(response){
                        return response;
                    });
                }
            } 
        })

        // Page appelé avant avant et après la connexion pour gérer les paramètres entrants et sortants du back
        .state('login', {
            url: '/login',
            templateUrl: 'src/partials/login-front.html',
            controller: 'LoginCtrl',
            data : { pageTitle: 'Digi-login' },
        })

        // Première page de Déconnexion. Redirige vers la page de /#/login avec des paramètres
        .state('logout', {
            url: '/logout',
            templateUrl: 'src/partials/logout.html',
            controller: 'LogoutCtrl',
            data : { pageTitle: 'Digi-logout' },
            resolve: {
                userInfos: function (globalServices) {
                    // Return   soit les infos de l'utilisateur connecté
                    //          soit une 401 unauthorized (bber ne pouvait pas renvoyer de 200 avec null comme réponse)
                    return globalServices.getLoggedUser({}).then(function(response){
                        return response;
                    });
                }
            }
        });

    // Oauth
    oauthConfigProvider.configure({
        authUrl:        baseURL + "/oauth/authorize", //ENV.oauthAuthorize,
        clientId:       oauthClientId, //ENV.oauthClientId,
        clientSecret:   oauthClientSecret, //ENV.oauthClientSecret,
        verifyUrl:      baseURL + "/oauth/token" //ENV.oauthToken
    });

    // Décoration du service http par le service "interceptor"
    $httpProvider.interceptors.push('HttpInterceptorService');
});