/**
 * @ngdoc controller
 * @name digiclashApp.controller:HexaViewCtrl
 * @description
 * Controller lié à la page du tableau de jeu
 */
digiclashApp
    .controller('HexaViewCtrl', [
        '$scope',
        '$rootScope',
        '$timeout',
        '$location',
        '$uibModal',
        '$filter',
        'HexaGrid',
        'globalServices',
        'userInfos',
        'endGameDate',
        function ($scope,
                  $rootScope,
                  $timeout,
                  $location,
                  $uibModal,
                  $filter,
                  HexaGrid,
                  globalServices,
                  userInfos,
                  endGameDate) {

            var vm = this;

            vm.userInfos = !userInfos.error ? userInfos.data : null;
            vm.isConnected = vm.userInfos !== null;
            vm.page = "board";
            $rootScope.page = vm.page;
            vm.isOpenModal = false;
            vm.imgHexaTeam = HexaGrid.getHexaPicto(vm.userInfos);
            vm.nbRemainingDays = HexaGrid.getNbRemainingDays(endGameDate);
            vm.isPortrait = window.innerWidth < window.innerHeight;
            vm.activeAnimation = $location.path() == '/home' && !vm.isPortrait;

            // Fix uniquement pour cette page
            $("body").css("overflow", "hidden");

            /**
             * @ngdoc method
             * @name vm.drawGrid
             * @methodOf digiclashApp.controller:HexaViewCtrl
             * @description Dessine le canvas représentant le tableau de jeu
             */
            vm.drawGrid = function(){

              // Scène avec la bibliothèque orbital control
              if (vm.isPortrait) {
                var cameraPosition = {x:0, y:250, z:120};
              } else {
                var cameraPosition = vm.activeAnimation ? {x:0, y:400, z:300} : {x:0, y:150, z:112};
              }

              vm.scene = new vg.Scene({
                element: document.getElementById('view'),
                cameraPosition: cameraPosition
              }, true);

              // this constructs the cells in grid coordinate space
              vm.grid = new vg.HexGrid();
              vm.grid.fromJSON(vm.gridData, vm.activeAnimation);

              vm.mouse = new vg.MouseCaster(vm.scene.container, vm.scene.camera);
              vm.board = new vg.Board(vm.grid);
              vm.plane = new vg.EditorPlane(vm.board.group, vm.grid, vm.mouse);

              // this will generate extruded hexagonal tiles
              vm.board.generateTilemap({
                tileScale: 0.96 // you might have to scale the tile so the extruded geometry fits the cell size perfectly
              });

              if (vm.grid.autogenerated) {
                vm.board.generateTilemap();
              }

              // Création de la grille de fond
              if (window.innerWidth > mobileWidth){
                var boardSize = 21;
                vm.plane.generatePlane(boardSize * boardSize * 1.8, boardSize * boardSize * 1.8);
                vm.plane.addHoverMeshToGroup(vm.scene.container);
                vm.board.generateOverlay(boardSize);
              }

              // Effet de particules (voir aussi la boucle d'update dans vm.updateGrid())
              if (showParticules) {
                var createGeometryTexture = function(geometry, size){
                  var data = new Float32Array( size * size * 3 );
                  var verticesLength = geometry.vertices.length;
                  for (var i = 0; i < size * size; i ++) {
                    if(verticesLength > i){
                      data[ i * 3 ]     = geometry.vertices[i].x;
                      data[ i * 3 + 1 ] = geometry.vertices[i].y;
                      data[ i * 3 + 2 ] = geometry.vertices[i].z;
                    } else {
                      data[ i * 3 ] = data[ i * 3 + 1 ] = data[ i * 3 + 2 ] = 0.0;
                    }
                  }
                  var dataTexture = new THREE.DataTexture(data, size, size, THREE.RGBFormat, THREE.FloatType);
                  dataTexture.needsUpdate = true;
                  return dataTexture;
                };

                var size = 64;
                var horizontalPlane = {vertices: []};
                for(var i = 0; i < size*size; i++){
                  horizontalPlane.vertices.push({
                    x: (((i%size)/size)-0.5)*1.2,
                    y: 0.0,
                    z: (((i/size)/size)-0.5)*1.2
                  });
                };


                var geometryTexture = createGeometryTexture(new THREE.SphereGeometry(0.5, size-1, size-1), size);

                var particleOptions = {
                  explodeRate: 4.0,
                  pointSize: 1.5,
                  colorFunctionString: 'color = vec4(0.6, 0.9, 0.9, 0.4);'
                };

                vm.particles = new Particles(vm.scene.renderer, vm.scene, particleOptions);
              }
              // fin effet de particule

              vm.scene.add(vm.board.group);
              vm.scene.focusOn(vm.board.group);

              var vec = new THREE.Vector3();

              // Mes à jour les territoires attables
              vm.attackableLands = vm.getAttackableLands();
              
              vm.mouse.signal.add(function(evt, tile) {                
                if (tile && !vm.isOpenModal){
                  if (evt === vg.MouseCaster.OVER) {
                    tile.toggle(true);
                  }

                  if (evt === vg.MouseCaster.OUT || evt === vg.MouseCaster.CLICK) {
                    tile.toggle(false);
                  }

                  if (evt === vg.MouseCaster.CLICK) {
                    vm.openModalAction('lg', null, tile);
                  }
                }
              }, this);

              vm.updateGrid();
            };

            /**
             * @ngdoc method
             * @name vm.getGrid
             * @methodOf digiclashApp.controller:HexaViewCtrl
             * @description Récupère les informations du tableau de jeu
             */
            vm.getGrid = function(){
              HexaGrid.getGrid().then(function(response){
                vm.gridData = response;
                vm.drawGrid();
              });
            };

            /**
             * @ngdoc method
             * @name vm.getTeams
             * @methodOf digiclashApp.controller:HexaViewCtrl
             * @description Récupère les informations sur toutes les équipes
             */
            vm.getTeams = function(){
              globalServices.getTeams({}).then(function(response){
                vm.teams = response.data.liste;
              });
            };

            /**
             * @ngdoc method
             * @name vm.getTeams
             * @methodOf digiclashApp.controller:HexaViewCtrl
             * @description Récupère les informations sur toutes les équipes
             */
            vm.getChallenges = function(){
              globalServices.getChallenges({}).then(function(response){
                vm.challenges = $filter('orderBy')(response.data.liste, 'niveau.level');
              });
            };

            /**
             * @ngdoc method
             * @name vm.updateGrid
             * @methodOf digiclashApp.controller:HexaViewCtrl
             * @description Dessine/Redessine le canvas
             */
            vm.updateGrid = function() {
              if ($rootScope.page == "board"){
                vm.mouse.update();
                vm.scene.autoMoveCamera(vm.isPortrait, vm.activeAnimation);
                vm.scene.render();
                if (showParticules){
                  vm.particles.update();
                }
                requestAnimationFrame(vm.updateGrid);
              }
            };

            /**
             * @ngdoc method
             * @name vm.getAttackableLands
             * @methodOf digiclashApp.controller:HexaViewCtrl
             * @description Récupère les territoires attaquables en fonction de la position des territoires de l'utilisateur connecté
             * + Les territoires qui sont actuellement attaqués sont éliminés des territoires attaquables
             */
            vm.getAttackableLands = function(){
              if (!vm.userInfos) return [];
              
              if (!vm.userInfos.ambassador) return [];

              var attackableLands = [];
              angular.forEach(vm.board.grid.cells, function(cell, cellKey){
                angular.forEach(vm.userInfos.equipe.territoires, function(territoire){
                  if (cell.q === territoire.coorQ && cell.r === territoire.coorR && cell.s === territoire.coorS){
                    angular.forEach(cell.adjacentCells, function(adjacentCell, adjacentCellKey){
                      var adjacentCellIsInTerritory = false;
                      // on vérifie que le territoire adjacent n'est pas un territoire de l'utilisateur connecté
                      angular.forEach(vm.userInfos.equipe.territoires, function(territoire2){
                        if (adjacentCell.q === territoire2.coorQ && adjacentCell.r === territoire2.coorR && adjacentCell.s === territoire2.coorS){
                          adjacentCellIsInTerritory = true;
                        }
                      });

                      if (!adjacentCellIsInTerritory && !adjacentCell.userData.estAttaque){
                        attackableLands.push(adjacentCell);
                      }
                    });
                  }
                });
              });
              return attackableLands;
            };

            /**
             * @ngdoc method
             * @name vm.openModalAction
             * @methodOf digiclashApp.controller:HexaViewCtrl
             * @description Ouverture modal qui gère les action du tableau de jeu
             */
            vm.openModalAction = function (size, parentSelector, tile) {
              vm.isOpenModal = true;

              var attackablePosition = false;
              var attackableTeam = false;
              var userTeamCanAttack = false;

              // Règle utilisée pendant quasi tout le jeu (sauf la dernière semaine)
              // Permet de savoir si le territoire est attaquable, en prenant en compte sa position
              /*angular.forEach(vm.attackableLands, function(cell){
                if (cell.q == tile.cell.q && cell.r == tile.cell.r && cell.s == tile.cell.s){
                  attackablePosition = true;
                }
              });*/

              // Règle appliquée pour la dernière semaine
              // Tous les territoires sont attaquables s'ils ne sont pas déjà attaqués
              if (vm.userInfos && vm.userInfos.ambassador && vm.userInfos.equipe.id != tile.cell.userData.equipe.id && !tile.cell.userData.estAttaque){
                attackablePosition = true;
              }

              angular.forEach(vm.teams, function(team){
                if (team.id == tile.cell.userData.equipe.id && team.estAttaquable){
                  attackableTeam = true;
                }

                if (vm.userInfos){
                  if (team.id == vm.userInfos.equipe.id && team.peutAttaquer){
                    userTeamCanAttack = true;
                  }
                }
              });

              var parentElem = parentSelector ? 
                angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
              var modalInstance = $uibModal.open({
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'src/partials/modals/tile.html', 
                controller: 'TileModalCtrl',
                controllerAs: 'mavm', // Modal Action View Model
                size: size,
                appendTo: parentElem,
                resolve: {
                  tile: function () {
                    return tile;
                  },
                  challenges: function () {
                    return vm.challenges;
                  },
                  userInfos: function() {
                    return vm.userInfos;
                  },
                  attackablePosition: function(){
                    return attackablePosition;
                  },
                  attackableTeam: function(){
                    return attackableTeam;
                  },
                  userTeamCanAttack: function(){
                    return userTeamCanAttack;
                  },
                  nbRemainingDaysGame : function(){
                    return vm.nbRemainingDays;
                  }
                }
              });

              modalInstance.result.then(function (selectedItem) {
                vm.isOpenModal = false;
              }, function () {
                vm.isOpenModal = false;
              });
            };

            /**
             * @ngdoc method
             * @name vm.openModalVictory
             * @methodOf digiclashApp.controller:HexaViewCtrl
             * @description Ouverture modal qui gère les action du tableau de jeu
             */
            vm.openModalVictory = function (size, parentSelector, tile) {
              vm.isOpenModal = true;
              var parentElem = parentSelector ? 
                angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
              var modalInstance = $uibModal.open({
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'src/partials/modals/victory.html', 
                controller: 'VictoryModalCtrl',
                controllerAs: 'mvvm', // Modal Victory View Model
                size: size,
                appendTo: parentElem,
                resolve: {
                  userInfos: function() {
                    return vm.userInfos;
                  },
                  nbRemainingDays : function(){
                    return vm.nbRemainingDays;
                  }
                }
              });

              modalInstance.result.then(function (selectedItem) {
                vm.isOpenModal = false;
              }, function () {
                vm.isOpenModal = false;
              });
            };

            vm.getGrid();
            vm.getTeams();
            vm.getChallenges();
            if (vm.nbRemainingDays < 0){
              vm.openModalVictory('md');
            }
}]);
