/**
 * @ngdoc controller
 * @name digiclashApp.controller:TerritoryCtrl
 * @description
 * Controller lié à la page de statistiques
 */
digiclashApp
    .controller('TerritoryCtrl', [
        '$scope',
        '$rootScope',
        '$timeout',
        '$location',
        '$filter',
        'globalServices',
        'userInfos',
        'HexaGrid',
        function ($scope,
                  $rootScope,
                  $timeout,
                  $location,
                  $filter,
                  globalServices,
                  userInfos,
                  HexaGrid) {

            var vm = this;

            // First, get rid of the scroll bug
            if ($rootScope.page == "board"){
              location.reload();
            } else {
              vm.page = "territory";
              $rootScope.page = vm.page;
            }

            vm.userInfos = !userInfos.error ? userInfos.data : null;
            vm.isConnected = vm.userInfos !== null;
            vm.imgHexaTeam = HexaGrid.getHexaPicto(vm.userInfos);

            if (!vm.userInfos){
              $location.path('#/home')
            }
            
            /**
             * @ngdoc method
             * @name vm.getTerritories
             * @methodOf digiclashApp.controller:TerritoryCtrl
             * @description Récupère les territoires actuels et perdus
             */
            vm.getTerritories = function(){
              globalServices.getHistoryTerritories({}).then(function(response){
                vm.territories = response.data;
              });
            };

            vm.getTerritories();
}]);
