/**
 * @ngdoc service
 * @name digiclashApp.factory:HttpInterceptorService
 * @description
 * HttpInterceptorService comme sur OneHR
 */
digiclashApp
    .factory('HttpInterceptorService', ['$q', '$location',
    function ($q, $location) { // Ajouter dépendance au service user message

        return {

            response: function (response) {
                // Appelé avant que la promesse ne soit résolue : response.status === 200
                // TODO Tester les messages en retour des services spring / hasErreur / avec retour : $q.reject(rejection);
                return response || $q.when(response);
            },

            responseError: function (rejection) {
                /* Appelé quand une requête XHR retourne un code statut erreur */

                // TODO : logger pour message technique
                // logger.debug("Intercepteur : nous interceptons l'erreur " + "Rejection = " + rejection);
                // logger.debug("HTTP - Erreur " + rejection.status + " : " + rejection.statusText);


                if (rejection.status == 0) {
                    // TODO : log technique
                    // Cette erreur est soulevée quand le serveur portail-core n'est pas accessible
                    // Ou quand il y a des problèmes de cors
                    // logger.debug("HTTP - Erreur 0 : Problème de connexion avec le serveur");
                    //userMessage.msg = "Impossible d'établir une connexion avec le serveur.";
                    //UserMessagesService.addMessage(userMessage);

                } else if (rejection.status == 400) {
                    // The interceptor "blocks" the error;
                    // and the success callback will be executed.
                    //userMessage.msg = "Veuillez corriger les erreurs indiquées";
                    //UserMessagesService.addMessage(userMessage);

                    //rejection.data = {status: 400, description: rejection.statusText};

                    return rejection;
                } else if (rejection.status == 401) {
                    // The interceptor "blocks" the error;
                    // and the success callback will be executed.
                    //rejection.data = {status: 401, description: 'unauthorized'};
                    //$location.path('/logout');

                    //userMessage.msg = "Requête non autorisée, authentification requise.";
                    //UserMessagesService.addMessage(userMessage);

                    return rejection.data;
                } else if (rejection.status == 403) {
                    // The interceptor "blocks" the error;
                    // and the success callback will be executed.
                    //rejection.data = {status: 403, description: 'unauthorized'};

                    //userMessage.msg = "Requête non autorisée, permissions insuffisantes."; 
                    //UserMessagesService.addMessage(userMessage);

                    return rejection.data;
                } else if (rejection.status == 404) {
                    // The interceptor "blocks" the error;
                    // and the success callback will be executed.
                    // rejection.data = {status: 404, description: 'unauthorized'};

                    // userMessage.msg = "Ressource non trouvée.";
                    // UserMessagesService.addMessage(userMessage);

                    return rejection.data;
                } else {
                    // userMessage.msg = "Une erreur est survenue lors de l'appel au service.";
                    // UserMessagesService.addMessage(userMessage);
                    console.log('Une erreur est survenue lors de l appel au service.');
                }


                /*
                 $q.reject creates a promise that is resolved as
                 rejected with the specified reason.
                 In this case the error callback will be executed.
                 */
                return $q.reject(rejection);
            }

            // Méthodes optionnelles si besoin
//			request: function(config) {
//				// Appelé avant d'envoyer une nouvelle requête XHR
//				// Possibilité de manipuler les paramètres de la requête
////				$httpProvider.defaults.headers["delete"] = {
////					    'Content-Type': 'application/json;charset=utf-8'
////					  };
//				return config || $q.when(config);
//			},
//
//			requestError: function(rejection) {
//				// Appelé quand une autre requête est en erreur
//				// Peut-être à supprimer, pas nécessaire ?
//				return $q.reject(rejection);
//			},


        }
    }
]);
