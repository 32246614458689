/**
 * @ngdoc controller
 * @name digiclashApp.controller:logoutCtrl
 * @description
 * Déconnexion
 */
digiclashApp
    .controller('LogoutCtrl', function ($scope, $location, $http, oauth, userInfos) {

    	oauth.unAuthorize();
    	if (!userInfos.error){ // connecté
    		var redirectUri = $location.absUrl().replace($location.url(), '/login');
		    window.location.href = baseURL + '/logout.do?redirect_url=' + window.encodeURIComponent(redirectUri);
    	} else { // actuellement non connecté
    		$location.path('/login');
    	}
    
});