/**
 * @ngdoc controller
 * @name digiclashApp.controller:LoginCtrl
 * @description
 * Controller appelé avant et après une connexion
 */
digiclashApp
    .controller('LoginCtrl', [
        '$scope',
        '$rootScope',
        '$location',
        'oauth',
        function ($scope,
                  $rootScope,
                  $location,
                  oauth) {

            oauth.authorize().then(function (data) {
                $location.path("/home");
            }, function (reason) {
                // Failure code goes here
                $scope.error = reason;
            }); 

        }
    ]);
