/*
	Sets up and manages a THREEjs container, camera, and light, making it easy to get going.
	Also provides camera control.

	Assumes full screen.
 */
// 'utils/Tools'

/*
	Moditifications CVER :
		- sceneSettings
		- controlSettings
		- Animation à l'arrivée sur le plateau

*/

vg.Scene = function(sceneConfig, controlConfig) {
	var sceneSettings = {
		element: document.body,
		alpha: true,
		antialias: true,
		clearColor: '#fff',
		sortObjects: false,
		fog: new THREE.Fog(0x000000, 290, 410),
		light: new THREE.DirectionalLight(0xffffff),
		lightPosition: null,
		cameraType: 'PerspectiveCamera',
		cameraPosition: null, // {x, y, z}
		orthoZoom: 4
	};

	var controlSettings = {
		minDistance: 80,
		maxDistance: 320,
		zoomSpeedBigScreen: 1.5,
		zoomSpeedSmallScreen: 0.8,
		noZoom: false,
		noPan: true,
		noRotate: false
	};

	sceneSettings = vg.Tools.merge(sceneSettings, sceneConfig);
	if (typeof controlConfig !== 'boolean') {
		controlSettings = vg.Tools.merge(controlSettings, controlConfig);
	}

	this.renderer = new THREE.WebGLRenderer({
		alpha: sceneSettings.alpha,
		antialias: sceneSettings.antialias
	});
	this.renderer.setClearColor(sceneSettings.clearColor, 0);
	this.renderer.sortObjects = sceneSettings.sortObjects;

	this.width = window.innerWidth;
	this.height = window.innerHeight;

	this.orthoZoom = sceneSettings.orthoZoom;

	this.controlSettings = controlSettings; // pas utilisé pas threeJS ou orbit Control mais par vg.Scene

	this.container = new THREE.Scene();
	this.container.fog = sceneSettings.fog;

	this.doneAnimation = false;

	this.container.add(new THREE.AmbientLight(0xdddddd));

	if (!sceneSettings.lightPosition) {
		sceneSettings.light.position.set(-1, 1, -1).normalize();
	}
	this.container.add(sceneSettings.light);

	if (sceneSettings.cameraType === 'OrthographicCamera') {
		var width = window.innerWidth / this.orthoZoom;
		var height = window.innerHeight / this.orthoZoom;
		this.camera = new THREE.OrthographicCamera(width / -2, width / 2, height / 2, height / -2, 1, 5000);
	}
	else {
		this.camera = new THREE.PerspectiveCamera(50, this.width / this.height, 1, 5000);
	}

	this.contolled = !!controlConfig;
	if (this.contolled) {
		this.controls = new THREE.OrbitControls(this.camera, this.renderer.domElement);
		//this.controls.minDistance = controlSettings.minDistance;
		//this.controls.maxDistance = controlSettings.maxDistance;
		if (window.innerWidth < 900){
			this.controls.zoomSpeed = controlSettings.zoomSpeedSmallScreen;
		} else {
			this.controls.zoomSpeed = controlSettings.zoomSpeedBigScreen;
		}
		this.controls.noZoom = controlSettings.noZoom;
		this.controls.noPan = controlSettings.noPan;
		this.controls.noRotate = controlSettings.noRotate;
	}

	if (sceneSettings.cameraPosition) {
		this.camera.position.copy(sceneSettings.cameraPosition);
	}

	window.addEventListener('resize', function onWindowResize() {
		this.width = window.innerWidth;
		this.height = window.innerHeight;
		if (this.camera.type === 'OrthographicCamera') {
			var width = this.width / this.orthoZoom;
			var height = this.height / this.orthoZoom;
			this.camera.left = width / -2;
			this.camera.right = width / 2;
			this.camera.top = height / 2;
			this.camera.bottom = height / -2;
		}
		else {
			this.camera.aspect = this.width / this.height;
		}
		this.camera.updateProjectionMatrix();
		this.renderer.setSize(this.width, this.height);
	}.bind(this), false);

	this.attachTo(sceneSettings.element);
};

vg.Scene.prototype = {

	attachTo: function(element) {
		element.style.width = this.width + 'px';
		element.style.height = this.height + 'px';
		this.renderer.setPixelRatio(window.devicePixelRatio);
		this.renderer.setSize(this.width, this.height);
		element.appendChild(this.renderer.domElement);
	},

	add: function(mesh) {
		this.container.add(mesh);
	},

	remove: function(mesh) {
		this.container.remove(mesh);
	},

	render: function() {
		if (this.contolled) this.controls.update();
		this.renderer.render(this.container, this.camera);
	},

	autoMoveCamera: function(isPortrait, activeAnimation){
		if (isPortrait || !activeAnimation) { // finalement, pas d'animation en portrait
			this.controls.minDistance = this.controlSettings.minDistance;
			this.controls.maxDistance = this.controlSettings.maxDistance;
			this.doneAnimation = true;
		} else {
			var newY = this.camera.position.y;
			var newZ = this.camera.position.z;

			if (!this.doneAnimation && this.camera.position.y){
				if (isPortrait){
					var maxSpeed = 17;
					var totalZoomDistance = 100; // Distance en Y. (avant/après)
					var finalY = 250;
					var finalZ = 120;
					var ratioYZ = 1;
					var curSpeed = (newY - finalY) / totalZoomDistance * maxSpeed;
				} else { // paysage
					var maxSpeed = 17;
					var totalZoomDistance = 252; // Distance en Y.
					var finalY = 150;
					var finalZ = 112;
					var ratioYZ = finalY / finalZ;	
					var curSpeed = (newY - finalY) / totalZoomDistance * maxSpeed;
				}

				newY = newY - curSpeed;
				newZ = newZ - (curSpeed / ratioYZ);

				if (newY < finalY || newZ < finalZ || curSpeed < 0.25){
					this.doneAnimation = true;
					this.controls.minDistance = this.controlSettings.minDistance;
					this.controls.maxDistance = this.controlSettings.maxDistance;
					//this.controls.noZoom = this.controlSettings.noZoom;
					//this.controls.noPan = this.controlSettings.noPan;
					//this.controls.noRotate = this.controlSettings.noRotate;
				} else {
					this.camera.position.y = newY;
					this.camera.position.z = newZ;
				}
			}
		}
	},

	updateOrthoZoom: function() {
		if (this.orthoZoom <= 0) {
			this.orthoZoom = 0;
			return;
		}
		var width = this.width / this.orthoZoom;
		var height = this.height / this.orthoZoom;
		this.camera.left = width / -2;
		this.camera.right = width / 2;
		this.camera.top = height / 2;
		this.camera.bottom = height / -2;
		this.camera.updateProjectionMatrix();
	},

	focusOn: function(obj) {
		this.camera.lookAt(obj.position);
	}
};

vg.Scene.prototype.constructor = vg.Scene;
