/* CVER : Liste des modifications par rapport au fichier de base :
	-	constructeur
	-	select
	-	deselect
*/

vg.Tile = function(config) {
	config = config || {};
	var settings = {
		cell: null, // required vg.Cell
		geometry: null, // required threejs geometry
		material: null // not required but it would improve performance significantly
	};
	settings = vg.Tools.merge(settings, config);

	if (!settings.cell || !settings.geometry) {
		throw new Error('Missing vg.Tile configuration');
	}

	this.cell = settings.cell;
	if (this.cell.tile && this.cell.tile !== this) this.cell.tile.dispose(); // remove whatever was there
	this.cell.tile = this;

	this.uniqueID = vg.Tools.generateID();

	this.geometry = settings.geometry;
	this.material = settings.material;

	var loader = new THREE.TextureLoader();

	if (!this.material) {
		if (config.cell.userData.estAttaque){
			var texture = loader.load('src/img/pictos-board/' + config.cell.userData.photo);
			texture.repeat.set(0.1, 0.1);
			texture.offset.set(0.5, 0.5);
			var attack = loader.load('src/img/picto-attack.jpg');
			attack.repeat.set(0.075, 0.075);
			attack.offset.set(0.5, 0.5);

			this.material = new THREE.MeshPhongMaterial({
				color: config.cell.userData.equipe.couleur,
				specular: 0x1c2527,
				shininess: 90,
				map: attack,
				//bumpMap : texture,
				//bumpScale: 0.5,
				emissiveMap: texture,
				emissiveIntensity: 0.1,
				//aoMap: texture,
				//normalMap: texture,
				//displacementMap: texture,
				//displacementScale : 10,
				//displacementBias: 10,
				specularMap: texture,
				//alphaMap: texture,
				//envMap:texture,	
			});
		} else{
			var texture = loader.load('src/img/pictos-board/' + config.cell.userData.photo);
			texture.repeat.set(0.1, 0.1);
			texture.offset.set(0.5, 0.5);

			this.material = new THREE.MeshPhongMaterial({
				color: config.cell.userData.equipe.couleur,
				specular: 0x1c2527,
				shininess: 40,
				map: texture
			});
		}
		
	}

	this.sideMaterial = new THREE.MeshPhongMaterial({
		color: vg.Tools.randomizeRGB('30, 30, 30', 30),
		specular: 0x2F5F68,
		shininess: 90
	});

	this.objectType = vg.TILE;
	this.entity = null;
	this.userData = {};

	this.selected = false;
	this.highlight = '0x333333';

	var materials = [];
	materials.push(this.material);
	materials.push(this.sideMaterial);
	var multiFacesMaterial = new THREE.MeshFaceMaterial(materials);

	this.mesh = new THREE.Mesh(this.geometry, multiFacesMaterial);
	this.mesh.userData.structure = this;

	// create references so we can control orientation through this (Tile), instead of drilling down
	this.position = this.mesh.position;
	this.rotation = this.mesh.rotation;

	// rotate it to face "up" (the threejs coordinate space is Y+)
	this.rotation.x = -90 * vg.DEG_TO_RAD;
	this.mesh.scale.set(settings.scale, settings.scale, 1);

	if (this.material.emissive) {
		this._emissive = this.material.emissive.getHex();
	}
	else {
		this._emissive = null;
	}

	this._underAttack = config.cell.userData.estAttaque;
	if (this._underAttack){
		this._attack = attack;
		this._texture = texture;
	}
};

vg.Tile.prototype = {
	select: function() {
		if(this._underAttack){
			this.material.map = this._texture;
			this.sideMaterial.color = this.material.color;
		} else {
			if (this.material.emissive) {
				this.material.emissive.setHex(this.highlight);
			}
		}
		this.selected = true;
		return this;
	},

	deselect: function() {
		if(this._underAttack){
			this.material.map = this._attack;
			this.sideMaterial.color = vg.Tools.randomizeRGB('20, 20, 20', 20);
		} else {
			if (this._emissive !== null && this.material.emissive) {
				this.material.emissive.setHex(this._emissive);
			}
		}
		this.selected = false;
		return this;
	},

	toggle: function(force) {
		if (force === true){
			this.select();
		} else if (force === false){
			this.deselect();
		} else {
			if (this.selected) {
				this.deselect();
			}
			else {
				this.select();
			}
		}
		return this;
	},

	dispose: function() {
		if (this.cell && this.cell.tile) this.cell.tile = null;
		this.cell = null;
		this.position = null;
		this.rotation = null;
		if (this.mesh.parent) this.mesh.parent.remove(this.mesh);
		this.mesh.userData.structure = null;
		this.mesh = null;
		this.material = null;
		this.userData = null;
		this.entity = null;
		this.geometry = null;
		this._emissive = null;
	}
};

vg.Tile.prototype.constructor = vg.Tile;
