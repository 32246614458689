/*
	2D plane that the user moves mouse around on in order to build maps. Provides a working plane to navigate, and a visual aid for tile placement.

	@author Corey Birnbaum https://github.com/vonWolfehaus/
 */

 /*
	CVER :
		- Modification syntaxe
		- Modification paramétrage couleur
 */

vg.EditorPlane = function (scene, grid, mouse) {

		this.geometry = null;
		this.mesh = null;
		this.material = new THREE.MeshPhongMaterial({
			color: 0x040404,
			side: THREE.DoubleSide,
			fog: true,
			specular: 0x2F5F68,
			emissive: 0x091113,
			shininess: 30
		});

		this.scene = scene;
		this.grid = grid;

		this.hoverMesh = this.grid.generateTilePoly(new THREE.MeshBasicMaterial({
			color: 0x333333,
			side: THREE.DoubleSide
		}));

		this.mouse = mouse;
};

vg.EditorPlane.prototype = {

	generatePlane: function(width, height) {
		if (this.mesh && this.mesh.parent) {
			this.mesh.parent.remove(this.mesh);
		}
		this.geometry = new THREE.PlaneBufferGeometry(width, width, 1, 1);
		this.mesh = new THREE.Mesh(this.geometry, this.material);
		this.mesh.rotation.x = 90 * vg.DEG_TO_RAD;
		this.mesh.position.y -= 0.1;
		this.scene.add(this.mesh);
	},

	addHoverMeshToGroup: function(group) {
		if (this.hoverMesh.parent) {
			this.hoverMesh.parent.remove(this.hoverMesh);
		}
		group.add(this.hoverMesh);
	},

	update: function() {
		if (this.mouse.allHits.length && !this.mouse.pickedObject) {
			/*var cell = this.grid.pixelToCell(this.nexus.input.editorWorldPos);
			this.hoverMesh.position.copy(this.grid.cellToPixel(cell));
			this.hoverMesh.position.y += 0.1;
			this.hoverMesh.visible = true;*/
		}
		else {
			this.hoverMesh.visible = false;
		}
	}
};

vg.EditorPlane.prototype.constructor = vg.EditorPlane;
