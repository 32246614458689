/**
 * @ngdoc controller
 * @name digiclashApp.controller:StatsCtrl
 * @description
 * Controller lié à la page de statistiques
 */
digiclashApp
    .controller('StatsCtrl', [
        '$scope',
        '$rootScope',
        '$timeout',
        '$location',
        '$filter',
        'globalServices',
        'userInfos',
        'endGameDate',
        'HexaGrid',
        function ($scope,
                  $rootScope,
                  $timeout,
                  $location,
                  $filter,
                  globalServices,
                  userInfos,
                  endGameDate,
                  HexaGrid) {

            var vm = this;

            // First, get rid of the scroll bug
            if ($rootScope.page == "board"){
              location.reload();
            } else {
              vm.page = "statistiques";
              $rootScope.page = vm.page;
            }

            vm.userInfos = !userInfos.error ? userInfos.data : null;
            vm.isConnected = vm.userInfos !== null;
            vm.imgHexaTeam = HexaGrid.getHexaPicto(vm.userInfos);
            vm.nbRemainingDays = HexaGrid.getNbRemainingDays(endGameDate);
            
            /**
             * @ngdoc method
             * @name vm.getTeams
             * @methodOf digiclashApp.controller:StatsCtrl
             * @description Récupère les informations sur toutes les équipes
             */
            vm.getTeams = function(){
              globalServices.getTeams({}).then(function(response){
                vm.teams = response.data.liste;
                vm.getStatistiques();
              });
            };

            /**
             * @ngdoc method
             * @name vm.getStatistiques
             * @methodOf digiclashApp.controller:StatsCtrl
             * @description Récupère les statistiques de jeu de toutes les équipes
             */
            vm.getStatistiques = function(){
              globalServices.getStatistiques({}).then(function(response){
                var statistiques = response.data.liste;

                // ajoute le max d'infos des teams dans chaque statistique
                angular.forEach(statistiques, function(teamStats){
                  teamStats.team = _.findWhere(vm.teams, {id: teamStats.id});
                  teamStats.ambassador = _.findWhere(teamStats.team.membres, {ambassador: true});
                  teamStats.hexaImg = 'src/img/hexa/' + teamStats.team.couleur.substring(1) + '.png';
                  teamStats.stringTeam = HexaGrid.getTeamMembersToString(teamStats);
                });

                vm.statistiques = HexaGrid.sortClassification(statistiques);
              });
            };

            vm.getTeams();
}]);
