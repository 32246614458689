/**
 * @ngdoc service
 * @name digiclashApp.factory:HexaGrid
 * @description Diverses functions sur la gestion du tableau de jeu 
 */
digiclashApp
    .factory('HexaGrid', [
        '$q',
        '$filter',
        'globalServices',
        function ($q,
                  $filter,
                  globalServices
        ) {
          return {

            /**
             * @ngdoc method
             * @name getGrid
             * @methodOf digiclashApp.factory:HexaGrid
             * @description Function récupère le tableau de jeu depuis le serveur 
             * Surcharge avec des informations qui ne sont pas dans le back
             * et le dessine à l'écran
             */
            getGrid: function(){
              var deferred = $q.defer();

              var dataToSend = {
                data: {}
              };

              return globalServices.getGrid(dataToSend).then(function(response){

                // transforme les cells
                // les cells sont les infos qui permettent de créer les tiles
                var cells = [];
                angular.forEach(response.data.liste, function(cell){

                  var formatedCell = {
                    "r": cell.coorR, 
                    "s": cell.coorS,
                    "q": cell.coorQ,
                    "h": cell.coorH,
                    "walkable": true,
                    "userData": {
                      "completeCell": cell,
                      "categorie": cell.categorie,
                      "dernierAction": cell.dernierAction,
                      "equipe": cell.equipe,
                      "estAttaque": cell.estAttaque,
                      "nom": cell.nom,
                      "photo": cell.photo
                    }
                  }
                  cells.push(formatedCell);
                });

                var result = {
                  "size": 5,
                  "cellSize": 10,
                  "extrudeSettings": {
                    "amount": 18,
                    "bevelEnabled": true,
                    "bevelSegments": 1,
                    "steps": 1,
                    "bevelSize": 0.5,
                    "bevelThickness": 0.5
                  },
                  "autogenerated": true,
                  "cells": cells
                };

                deferred.resolve(result);
                return deferred.promise;
              });
            },

            /**
             * @ngdoc method
             * @name getHexaPicto
             * @methodOf digiclashApp.factory:HexaGrid
             * @description retourne l'url du picto hexa
             * retourne null si pas connecté
             */
            getHexaPicto: function(userInfos){
              if (!userInfos) return null;

              return 'src/img/hexa-border/' + userInfos.equipe.couleur.substring(1) + '.png';              
            },

            /**
             * @ngdoc method
             * @name getNbRemainingDays
             * @methodOf digiclashApp.factory:HexaGrid
             * @description retourne 0 si on est le dernier jour de jeu
             * retourne un nombre négatif si le jeu est fini
             * le jeu dure 6 semaines
             */
            getNbRemainingDays: function(endGameDate){
              if (!endGameDate) return null;

              //var momentEndGame = moment(endGameDate, 'YYYY-MM-DD').add(6, 'weeks');
              var momentEndGame = moment(endGameDate, 'YYYY-MM-DD');
              var currentDate = moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD').add(1, 'days');

              return momentEndGame.diff(currentDate, 'days');
            },

            /**
             * @ngdoc method
             * @name sortClassification
             * @methodOf digiclashApp.factory:HexaGrid
             * @description tri les équipes selon leur classement
             */
            sortClassification: function(classification){
              classification = $filter('orderBy')(classification, ['-nbTerritoire', '-level', '-point', 'nbDefendLost']);

              return classification;
            },

            /**
             * @ngdoc method
             * @name getTeamMembersToString
             * @methodOf digiclashApp.factory:HexaGrid
             * @description Function to string des membres d'une équipe.
             */
            getTeamMembersToString: function(team){
              var stringMembers = "";

              team.team.membres = $filter('orderBy')(team.team.membres, ['nom']);

              angular.forEach(team.team.membres, function(member){
                  stringMembers += member.prenom + ' ' + member.nom + '<br>'; 
              });
              return stringMembers;
            }
          };
        }
    ]);