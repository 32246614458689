/**
 * @ngdoc controller
 * @name digiclashApp.controller:TeamCtrl
 * @description
 * Controller lié à la page de statistiques
 */
digiclashApp
    .controller('TeamCtrl', [
        '$scope',
        '$rootScope',
        '$timeout',
        '$location',
        '$filter',
        'globalServices',
        'userInfos',
        'HexaGrid',
        function ($scope,
                  $rootScope,
                  $timeout,
                  $location,
                  $filter,
                  globalServices,
                  userInfos,
                  HexaGrid) {

            var vm = this;

            vm.disabledButtons = false;

            // First, get rid of the scroll bug
            if ($rootScope.page == "board"){
              location.reload();
            } else {
              vm.page = "team";
              $rootScope.page = vm.page;
            }

            vm.userInfos = !userInfos.error ? userInfos.data : null;
            vm.isConnected = vm.userInfos !== null;
            vm.imgHexaTeam = HexaGrid.getHexaPicto(vm.userInfos);
            
            if (vm.userInfos){
              vm.team = vm.userInfos.equipe.membres;
            } else {
              $location.path('#/home')
            }

            vm.sendTeamMessage = function(){
              vm.errorMessage = "";
              vm.sentTeamMessage = false;

              if (!vm.disabledButtons) {
                vm.disabledButtons = true;

                globalServices.sendTeamMessage({}).then(function(response){
                  vm.disabledButtons = false;

                  if (response.status < 400) {
                      vm.sentTeamMessage = true;
                  } else {
                      if (response.data instanceof Array) {
                          vm.errorMessage = response.data[0].message;
                      }
                      //$("body").scrollTop(0);
                  }
                });
              }
            }
}]);
