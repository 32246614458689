/**
 * @ngdoc service
 * @name globalServices
 * @requires $http  // provide any dependencies. you can have multiple @requires lines
**/
digiclashApp
    .factory('globalServices', [
        '$q',
        '$http',
        function (
          $q,
          $http
        ) {
          return {
            // Récupère le tableau de jeu depuis le serveur
            getGrid: function(params){
              return $http.post(baseURL + '/territoire/recherche', params.data).then(
                    params.success,
                    function (response) {
                        return $q.reject(response.data);
                    });
            },
            // les équipes
            getTeams: function(params){
              return $http.post(baseURL + '/equipe/recherche', {} ).then(
                    params.success,
                    function (response) {
                        return $q.reject(response.data);
                    });
            },
            // la liste des défis
            getChallenges: function(params){
              return $http.post(baseURL + '/defis/recherche', {} ).then(
                    params.success,
                    function (response) {
                        return $q.reject(response.data);
                    });
            },
            // les stats
            getStatistiques: function(params){
              return $http.post(baseURL + '/equipe/statistique', {} ).then(
                    params.success,
                    function (response) {
                        return $q.reject(response.data);
                    });
            },
            // les infos sur l'utilisateur connecté (ou non)
            getLoggedUser: function(params){
              return $http.get(baseURL + '/utilisateur', {} ).then(
                    params.success,
                    function (response) {
                        return $q.reject(response.data);
                    });
            },
            // Lancer attaquer 
            attack: function(params){
              return $http.post(baseURL + '/jeu/attaque', params.data).then(
                    params.success,
                    function (response) {
                        return $q.reject(response.data);
                    });
            },
            // Affecter un défi à un utilisateur
            affectUser: function(params){
              return $http.post(baseURL + '/jeu/defense/' + params.idAction + '/affectation', params.data).then(
                    params.success,
                    function (response) {
                        return $q.reject(response.data);
                    });
            },
            // Valider défense
            defend: function(params){
              return $http.post(baseURL + '/jeu/defense', params.data).then(
                    params.success,
                    function (response) {
                        return $q.reject(response.data);
                    });
            },
            // Renvoie la date de début du jeu
            startGameDate: function(params){
              return $http.get(baseURL + '/jeu/debut').then(
                    params.success,
                    function (response) {
                        return $q.reject(response.data);
                    });
            },
            getHistoryTerritories: function(params){
                return $http.get(baseURL + '/jeu/liste-territoire').then(
                    params.success,
                    function (response) {
                        return $q.reject(response.data);
                    });
            },
            // envoie un mail de motivation à l'équipe
            sendTeamMessage: function(params){
                return $http.get(baseURL + '/jeu/notification').then(
                    params.success,
                    function (response) {
                        return $q.reject(response.data);
                    });
            }
          };
        }
    ]);