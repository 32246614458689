/**
 * @ngdoc controller
 * @name digiclashApp.controller:VictoryModalCtrl
 * @description
 * Controller de la pop in qui gère les actions de jeu du plateau
 */
digiclashApp
    .controller('VictoryModalCtrl', function ($uibModalInstance, $window, userInfos, nbRemainingDays, globalServices, HexaGrid) {

    var mvvm = this;

    mvvm.nbRemainingDays = nbRemainingDays;

     /**
     * @ngdoc method
     * @name mvvm.isTileToDefend
     * @methodOf digiclashApp.controller:VictoryModalCtrl
     * @description retourne true que si l'utilisateur connecté doit défendre une attaque sur cette cellule
     */
    mvvm.isTileToDefend = function(){
      if (!userInfos) return false;

      if (userInfos.equipe.id == mvvm.dataCell.equipe.id && mvvm.dataCell.estAttaque){
        return true;
      } else {
        return false;
      }
    };

    /**
     * @ngdoc method
     * @name mvvm.getTeams
     * @methodOf digiclashApp.controller:StatsCtrl
     * @description Récupère les informations sur toutes les équipes
     */
    mvvm.getTeams = function(){
      globalServices.getTeams({}).then(function(response){
        mvvm.teams = response.data.liste;
        mvvm.getStatistiques();
      });
    };

    /**
     * @ngdoc method
     * @name mvvm.getStatistiques
     * @methodOf digiclashApp.controller:StatsCtrl
     * @description Récupère les statistiques de jeu de toutes les équipes
     */
    mvvm.getStatistiques = function(){
      globalServices.getStatistiques({}).then(function(response){
        var statistiques = response.data.liste;

        // ajoute le max d'infos des teams dans chaque statistique
        angular.forEach(statistiques, function(teamStats){
          teamStats.team = _.findWhere(mvvm.teams, {id: teamStats.id});
          teamStats.ambassador = _.findWhere(teamStats.team.membres, {ambassador: true});
          teamStats.hexaImg = 'src/img/hexa/' + teamStats.team.couleur.substring(1) + '.png';
          teamStats.stringTeam = HexaGrid.getTeamMembersToString(teamStats);
        });

        mvvm.statistiques = HexaGrid.sortClassification(statistiques);
      });
    };

    mvvm.getTeams();

    mvvm.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
});