/**
 * @ngdoc controller
 * @name digiclashApp.controller:TileModalCtrl
 * @description
 * Controller de la pop in qui gère les actions de jeu du plateau
 */
digiclashApp.controller('TileModalCtrl', 
  function (  $uibModalInstance, 
              $window, 
              $location, 
              tile, 
              challenges, 
              userInfos, 
              attackablePosition,
              attackableTeam,
              userTeamCanAttack,
              nbRemainingDaysGame, 
              globalServices
  ) {

    var mavm = this;
    mavm.tile = tile;
    mavm.dataCell = mavm.tile.cell.userData;
    mavm.challenges = challenges;
    mavm.attackablePosition = attackablePosition;
    mavm.attackableTeam = attackableTeam;
    mavm.userTeamCanAttack = userTeamCanAttack;
    mavm.userInfos = userInfos;
    mavm.toDefend = false;
    mavm.nbRemainingDaysGame = nbRemainingDaysGame; // nombre de jours restants avant la fin du jeu
    mavm.disabledButtons = false;

    /**
     * @ngdoc method
     * @name mavm.getRemainingTimeToDefend
     * @methodOf digiclashApp.controller:TileModalCtrl
     * @description retourne le nombre de jours/heures restant pour défendre
     */
    mavm.getRemainingTimeToDefend = function(){
      if (!mavm.dataCell.estAttaque)  return null;
      if (moment(mavm.dataCell.dernierAction.fin).diff(moment()) < 0) return null; // date de fin du challenge dépassée
      
      var nbDays = moment(mavm.dataCell.dernierAction.fin).diff(moment(), 'days');
      var nbHours = moment(mavm.dataCell.dernierAction.fin).diff(moment(), 'hours');
      var nbMinutes = moment(mavm.dataCell.dernierAction.fin).diff(moment(), 'minutes');
      
      return {
        days: nbDays,
        hours: nbHours % 24,
        minutes : nbMinutes % 60
      };
    };

     /**
     * @ngdoc method
     * @name mavm.isTileToDefend
     * @methodOf digiclashApp.controller:TileModalCtrl
     * @description retourne true que si l'utilisateur connecté doit défendre une attaque sur cette cellule
     * Plus précisément : Territoire de ma team + territoire attaqué + (ambassadeur OU utilisateur désigné)
     */
    mavm.isTileToDefend = function(){
      if (!userInfos) return false;

      var iAmDefender = false;
      if (mavm.userInfos){
        if (mavm.dataCell.dernierAction){
          if (mavm.dataCell.dernierAction.utilisateurDefenseur){
            if (mavm.dataCell.dernierAction.utilisateurDefenseur.id == mavm.userInfos.id){
              iAmDefender = true;
            }
          }
        }
      }
      
      if (mavm.userInfos.equipe.id == mavm.dataCell.equipe.id && mavm.dataCell.estAttaque && (mavm.userInfos.ambassador || iAmDefender)){
        return true;
      } else {
        return false;
      }
    };

    /**
     * @ngdoc method
     * @name mavm.defend
     * @methodOf digiclashApp.controller:TileModalCtrl
     * @description Valider une défense
     */
    mavm.defend = function(){
      mavm.errorMessage = "";

      var dataToSend = {
        data : {
          idAction: mavm.dataCell.dernierAction.id,
          justificatif: mavm.doneLink
        }
      }

      if (!mavm.doneLink){
        mavm.errorMessage = "Veuillez ajouter un lien vers la preuve de la réalistion de votre défi.";
      } else if (mavm.doneLink.substring(0,4) != "http") {
        mavm.errorMessage = "Votre preuve est invalide. Elle doit commencer par http...";
      } else if (!mavm.disabledButtons) {
        mavm.disabledButtons = true;
        
        globalServices.defend(dataToSend).then(function(response){
          mavm.disabledButtons = false;
          if (response.status < 400) {
              $location.path('/home/action');
              setTimeout(function () {
                $window.location.reload();
              });
          } else {
              if (response.data instanceof Array) {
                  mavm.errorMessage = response.data[0].message;
              }
              $("body").scrollTop(0);
          }
        });
      }
    };

    /**
     * @ngdoc method
     * @name mavm.attack
     * @methodOf digiclashApp.controller:TileModalCtrl
     * @description Valider une défense
     */
    mavm.attack = function(){
      mavm.errorMessage = "";

      if (!mavm.selectedChallenge){
        mavm.errorMessage = "Veuillez sélectionner un défi.";
      } else if (!mavm.disabledButtons) {
        mavm.disabledButtons = true;

        var dataToSend = {
          data : {
            idDefis: mavm.selectedChallenge.id,
            idTerritoire: tile.cell.userData.completeCell.id
          }
        }

        globalServices.attack(dataToSend).then(function(response){
          mavm.disabledButtons = false;
          if (response.status < 400) {
              $location.path('/home/action');
              setTimeout(function () {
                  $window.location.reload();
              });
          } else {
              if (response.data instanceof Array) {
                  mavm.errorMessage = response.data[0].message;
              }
              $("body").scrollTop(0);
          }
        });
      }
    };

    /**
     * @ngdoc method
     * @name mavm.affectUser
     * @methodOf digiclashApp.controller:TileModalCtrl
     * @description Affecter un défi à un utilisateur
     */
    mavm.affectUser = function(){
      mavm.errorMessage = "";

      if (!mavm.selectedChampion){
        mavm.errorMessage = "Veuillez choisir un champion.";
      } else if (!mavm.disabledButtons) {
        mavm.disabledButtons = true;

        var dataToSend = {
          idAction: mavm.dataCell.dernierAction.id,
          data : {
            idUtilisateur: mavm.selectedChampion.id
          }
        }

        globalServices.affectUser(dataToSend).then(function(response){
          mavm.disabledButtons = false;
          if (response.status < 400) {
              $location.path('/home/action');
              setTimeout(function () {
                $window.location.reload();
              });
          } else {
              if (response.data instanceof Array) {
                  mavm.errorMessage = response.data[0].message;
              }
              $("body").scrollTop(0);
          }
        });
      }
    };

    mavm.challengeFilter = function(challenge){
      if (challenge.niveau.level <= userInfos.equipe.niveau.level)  
        return true;
      else
        return false;
    };

    mavm.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };

    mavm.toDefend = mavm.isTileToDefend();
    mavm.remainingTimeToDefend = mavm.getRemainingTimeToDefend();
});